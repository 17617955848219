import { create } from 'zustand';

const useBearStore = create((set, get) => ({
  auth: {
     isValid: null,
     setIsValid: (value) => set((state) => ({ ...state, auth: { ...state.auth, isValid: value } })),
},
isTokenSet: false,
  SetToken: () => {
    try {
     const storedToken = localStorage.getItem('authToken');
     if(storedToken)
     {
          const isvalid = {
               isValidated: true,
               token: storedToken,
          };
          get().auth.setIsValid(isvalid);
          console.log(isvalid);
          set({ isTokenSet: true });
     }
    } catch (error) {
     
    }
  },
  LogOut: () => {
     try {
          localStorage.removeItem('authToken');
          const value = get().auth.isValid;
          set({ isTokenSet: false });
          console.log(value);
     } catch (error) {
          
     }
  }
}));

export default useBearStore;
// const storedToken = localStorage.getItem('authToken');
//     console.log('checked the stored token')
//     if (storedToken) {
//          console.log(storedToken);
     
//      set({ isValid: true });

//     }
//     else{
//           set({ isValid: false });
//     }