import data from '../../Data/balance'
function Customer() {
     return (
          <div className="text-light rounded py-5 px-3 customer">
               <b>
                    <h3>Customer Details</h3>
                    <p>Account Name: {data.name} </p>
                    <p>Account Number: 5252546457</p>
                    <p>Email: {data.email} </p>
               </b>
          </div>
     );
}
export default Customer;