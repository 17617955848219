import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Landing from '../Pages/landing';
import Home from '../Pages/Home/home';
import Login from '../Pages/Login';
import useBearStore from "../State/state";

function App() {
     const { auth } = useBearStore();
     const { isValid } = auth;
   
     // Handle potential initial loading state
     const [isAuthenticated, setIsAuthenticated] = useState(null);
     
   
     useEffect(() => {
       // Update state based on auth state changes
       setIsAuthenticated(isValid);
     }, [isValid]);

  

  return (
//     <Router>
//       <Routes>
//         <Route exact path="/" element={<Landing />} />
//         <Route path="/Home" element={isAuthenticated ? <Home /> : <Navigate to="/Login" />} />
//         <Route path="/Login" element={<Login />} />
//       </Routes>
//     </Router>
         <div style={{width:"100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <marquee direction="left"><h1 className='text-danger'>😂😂🤣🤣😆😆😅😅</h1></marquee>
         </div>
  );
}

export default App;
